let style_tab = [ "小清新", "Lolita" , "Jk制服", "汉服", "cos服", "民国服", "时尚服装", "礼服", "特殊服装" ]
let rent_sale = [ "出租", "销售"]
// [
//     {
//         id: 1,
//         name: "小清新"
//     },
//     {
//         id: 2,
//         name: "Lolita"
//     },
//     {
//         id: 3,
//         name: "Jk制服"
//     },
//     {
//         id: 4,
//         name: "汉服"
//     },
//     {
//         id: 5,
//         name: "cos服"
//     },
//     {
//         id: 6,
//         name: "民国服"
//     },
//     {
//         id: 7,
//         name: "时尚服装"
//     },
//     {
//         id: 8,
//         name: "礼服"
//     },
//     {
//         id: 9,
//         name: "特殊服装"
//     }
// ]

export { style_tab, rent_sale}