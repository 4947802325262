<template>
  <div class="camera-info-box">
    <!-- 面包屑导航 -->
    <bread-crumb :breadcrumb="breadcrumb"></bread-crumb>
    <!-- 表单数据部分 -->
    <div class="camera-info-form">
      <!-- 基本信息 -->
      <el-form
          :model="form"
          :rules="rules"
          ref="cameraForm"
          label-width="100px"
      >
        <!-- 相机名称 -->
        <el-form-item label="服饰名称">
          <el-input
              v-model="form.goods_name"
              class="form-input"
              placeholder="请输入服饰名称"
          >
          </el-input>
        </el-form-item>
        <!-- 封面图片 -->
        <el-form-item label="封面图片">
          <div style="color: #e6a23c">建议图片大小2M以内</div>
          <div class="camera-info-pic-box" v-if="form.goods_skin">
            <div class="camera-info-pic">
              <img :src="form.goods_skin" alt="" />
              <span class="camera-info-pic-delete-box">
                <span
                    class="camera-info-pic-delete"
                    @click="handleSkinRemove()"
                >
                  <i class="el-icon-delete"></i>
                </span>
              </span>
            </div>
          </div>
          <el-upload
              action="#"
              ref="skinPic"
              list-type="picture-card"
              :auto-upload="false"
              :on-preview="handlePictureCardPreview"
              :on-change="handleSkinChange"
              :limit="1"
              class="camera-info-pic-upload"
          >
            <i class="el-icon-plus"></i>
            <template v-slot:file="{ file }">
              <div class="camera-info-pic">
                <img :src="file.url" alt="" />
                <span class="el-upload-list__item-actions">
                  <span
                      class="el-upload-list__item-delete"
                      @click="handleSkinRemove()"
                  >
                    <i class="el-icon-delete"></i>
                  </span>
                </span>
              </div>
            </template>
          </el-upload>
        </el-form-item>
        <!-- 商品图片 -->
        <el-form-item label="商品图片">
          <div style="color: #e6a23c">建议图片大小2M以内</div>
          <div class="camera-info-pic-box">
            <div
                class="camera-info-pic"
                v-for="(item, index) in form.goods_img"
                :key="index"
            >
              <img :src="item" alt="" />
              <span class="camera-info-pic-delete-box">
                <span
                    class="camera-info-pic-delete"
                    @click="handleRemove(item)"
                >
                  <i class="el-icon-delete"></i>
                </span>
              </span>
            </div>
          </div>
          <el-upload
              action="#"
              list-type="picture-card"
              :auto-upload="false"
              :on-preview="handlePictureCardPreview"
              :on-change="onChange"
              :show-file-list="false"
              class="camera-info-pic-upload"
          >
            <i class="el-icon-plus"></i>
          </el-upload>
        </el-form-item>
        <!-- 三围 -->
        <el-form-item label="三围">
          <el-input
              v-model="form.bwh"
              class="form-input"
              placeholder="请输入三围"
          >
          </el-input>
        </el-form-item>
        <!-- 尺码 -->
        <el-form-item label="尺码">
          <el-input
              v-model="form.size"
              class="form-input"
              placeholder="请输入尺码"
          >
          </el-input>
        </el-form-item>
        <!-- 联系电话 -->
        <el-form-item label="联系方式" prop="contact_way">
          <el-radio-group v-model="form.contact_way" class="form-radio-group">
            <el-radio label="1">QQ</el-radio>
            <el-radio label="2">微信</el-radio>
            <el-radio label="3">电话</el-radio>
          </el-radio-group>
          <el-input
              v-model="form.phone"
              class="form-input"
              placeholder="请输入QQ/微信/电话"
          >
          </el-input>
        </el-form-item>
        <!-- 风格 -->
        <el-form-item label="风格">
          <el-checkbox-group v-model="form.style">
            <el-checkbox v-for="item in style_tab" :key="item" :label="item">
            </el-checkbox>
          </el-checkbox-group>
        </el-form-item>
        <!-- 租售 -->
        <el-form-item label="租售">
          <el-checkbox-group v-model="rentSale" @change="oncheckbox">
            <el-checkbox v-for="item in rent_sale" :key="item" :label="item">
            </el-checkbox> </el-checkbox-group
          >(必选其一)
        </el-form-item>
        <!-- 押金价格 -->
        <el-form-item label="押金价格" prop="deposit">
          <el-input
              v-model="form.deposit"
              class="form-input"
              placeholder="请输入押金价格"
          >
          </el-input>
        </el-form-item>
        <!-- 租赁价格 -->
        <el-form-item label="租赁价格" prop="price">
          <el-input
              v-model="form.price"
              class="form-input"
              placeholder="请输入租赁价格"
          >
          </el-input>
        </el-form-item>
        <!-- 销售价格 -->
        <el-form-item label="销售价格" prop="sellprice">
          <el-input
              v-model="form.sellprice"
              class="form-input"
              placeholder="请输入销售价格"
          >
          </el-input>
        </el-form-item>
        <!-- 所在地区 -->
        <el-form-item label="所在地区">
          <!-- <el-cascader
                        :options="cityOption"
                        v-model="form.district">
                    </el-cascader> -->
          <area-cascader
              v-model="form.district"
              type="text"
              :level="1"
              :data="$pcaa"
              placeholder="请选择地址"
              class="center-basic-data-set-city"
          >
          </area-cascader>
        </el-form-item>
        <!-- 详细地址 -->
        <el-form-item label="详细地址">
          <el-input
              v-model="form.address"
              class="form-input-address"
              placeholder="请输入详细地址"
              @keyup.enter.native="handleSearch"
          >
            <el-button
                slot="append"
                icon="el-icon-search"
                @click="handleSearch"
            >
            </el-button>
          </el-input>
          <div class="center-camera-info-map">
            <b-map
                :map-height="300"
                :map-position="position"
                :map-change="setLeaseDetailChange"
                ref="mapSearch"
            >
            </b-map>
          </div>
        </el-form-item>
        <!-- 简介 -->
        <el-form-item label="简介">
          <el-input
              v-model="form.synopsis"
              type="textarea"
              :rows="2"
              placeholder="请输入简介"
          >
          </el-input>
        </el-form-item>
        <!-- 服饰详情 -->
        <el-form-item label="服饰详情">
          <!-- <el-input
                        type="textarea"
                        :rows="3"
                        placeholder="请输入内容"
                        v-model="form.summary">
                    </el-input> -->
          <div style="color: #f56c6c">填写完整的详情有助于提高出租率</div>
          <quill-editor v-model="form.summary"> </quill-editor>
        </el-form-item>
        <el-form-item>
          <el-checkbox v-model="form.is_sale" :true-label="0" :false-label="1">
            将此服饰下架
          </el-checkbox>
          <span style="color: #c0c4cc">(勾选后服饰将无法进行租赁)</span>
        </el-form-item>
        <el-form-item>
          <el-button type="warning" @click="handleSubmit(form)">
            保存
          </el-button>
        </el-form-item>
      </el-form>
      <el-dialog :visible.sync="dialogVisible">
        <img width="100%" :src="dialogImageUrl" alt="" />
      </el-dialog>
    </div>
  </div>
</template>

<script>
import { mapState, mapMutations, mapActions } from "vuex";
import { style_tab, rent_sale } from "@/tool/clothesStyle";

export default {
  data() {
    let validataPrice = (rule, value, callback) => {
      console.log(value, "value");
      if (value === "" && this.isprice) {
        callback(new Error("请输入租赁价格"));
      } else if (this.form.deposit > 0 && value > this.form.deposit * 0.1) {
        callback(new Error("单日租金最高为押金的10%"));
      } else {
        callback();
      }
    };
    let validatasellpricePrice = (rule, value, callback) => {
      console.log(value, "value");
      if (value === "" && this.issellprice) {
        callback(new Error("请输入销售价格"));
      }
      /*else if (this.form.deposit > 0 && value > this.form.deposit * 0.1) {
        callback(new Error("单日租金最高为押金的10%"));
      } */
      else {
        callback();
      }
    };
    return {
      style_tab,
      rent_sale,
      // 面包屑导航参数
      breadcrumb: [
        {
          path: "/center/camera",
          path_name: "服饰管理",
        },
        {
          path: "/center/camera/info",
          path_name: "服饰详情",
        },
      ],
      rentSale: [],
      order_type:1,
      // 表单
      form: {
        // 基本信息
        goods_name: "",
        goods_skin: "",
        goods_img: [],
        bwh: "",
        size: "",
        contact_way: "",
        phone: "",
        style: [],
        deposit: "",
        price: "",
        sellprice: "",
        district: [],
        address: "",
        synopsis: "",
        summary: "",
        is_sale: 1,
      },

      position: {
        lat: "", // 纬度
        lng: "", // 经度
      },
      rules: {
        contact_way: [
          { required: true, message: "选择联系方式", trigger: "blur" },
        ],
        deposit: [
          { required: true, message: "请输入押金价格", trigger: "blur" },
        ],
        price: [
          { required: this.isprice, validator: validataPrice, trigger: "blur" },
        ],
        sellprice: [
          {
            required: this.issellprice,
            validator: validatasellpricePrice,
            trigger: "blur",
          },
        ],
      },
      // 图片上传
      skin_file: {},
      dialogImageUrl: "",
      dialogVisible: false,
      isprice: false,
      issellprice: false,
    };
  },
  computed: mapState({
    lease_detail: (state) => state.user.lease_detail,
  }),
  components: {
    // 面包屑导航组件
    breadCrumb: () => import("@/components/breadCrumb"),
    bMap: () => import("@/components/bMap"),
  },
  methods: {
    ...mapMutations(["setLeaseDetail", "setLeaseDetailChange"]),
    ...mapActions([
      "handleAddLease",
      "handleChangeLease",
      "handleUploadLeasePic",
      "getLeaseDetail",
    ]),
    setForm(val) {
      let saleType = [];
      switch (val.orderType) {
        case 1:saleType = ["出租"];break;
        case 2:saleType = ["销售"];break;
        case 3:saleType = ["出租", "销售"];break;
      }
      console.log(saleType)
      if (val.goods_id) {
        this.form = {
          goods_name: val.goods_name,
          goods_skin: val.goods_img,
          goods_img: val.images,
          bwh: val.bwh || "",
          size: val.size || "",
          contact_way: val.linkType || 0,
          phone: val.phone || "",
          style: val.style || [],
          deposit: val.deposit,
          price: val.price,
          sellprice: val.sellPrice,
          district: val.goods_city,
          address: val.goods_address,
          synopsis: val.synopsis,
          summary: val.detail,
          is_sale: val.showStatus,
        };
        this.rentSale = saleType;
        this.position = {
          lat: val.position.lat,
          lng: val.position.long,
        };
      } else {
        this.form.district = val.goods_city;
        if (val.goods_address) this.form.address = val.goods_address;
        this.position = {
          lat: val.position.lat,
          lng: val.position.long,
        };
      }
    },
    oncheckbox(e) {
      if (e.length) {
        this.issellprice = false;
        this.isprice = false;
        e.forEach((res) => {
          if (res == "销售") {
            this.issellprice = true;
          } else if (res == "出租") {
            this.isprice = true;
          }
        });
      } else {
        this.issellprice = true;
        this.isprice = true;
      }
    },
    handleBase64(file) {
      return new Promise((resolve) => {
        if (file.uid) {
          let images = "",
              reader = new FileReader();
          reader.onload = () => {
            let _base64 = reader.result,
                BASE64 = _base64.split(",");
            images = BASE64[1];
            resolve(images);
          };
          reader.readAsDataURL(file);
        } else {
          resolve("");
        }
      });
    },
    onChange(file, fileList) {
      const isLt2M = file.size / 1024 / 1024 < 2;
      if (!isLt2M) {
        this.$message.error("商品图片上传大小不能超过 2MB!");
        return;
      }
      this.handleBase64(file.raw).then((res) => {
        this.handleUploadLeasePic(res).then((res) => {
          this.$message({
            showClose: true,
            message: res.msg,
            type: res.type ? "success" : "error",
          });
          if (res.type) this.form.goods_img.push(res.result);
        });
      });
    },
    handleRemove(file) {
      // 图片上传组件移除钩子函数
      this.form.goods_img.map((item, index) => {
        if (file === item) {
          this.form.goods_img.splice(index, 1);
        }
      });
    },
    handleSkinChange(file, fileList) {
      this.skin_file = file.raw;
    },
    handleSkinRemove() {
      this.form.goods_skin = "";
      this.skin_file = {};
      this.$refs.skinPic.clearFiles();
    },
    // 图片上传列表放大功能
    handlePictureCardPreview(file) {
      this.dialogImageUrl = file.url;
      this.dialogVisible = true;
    },
    handleSearch() {
      // 搜索功能
      let val = this.form.district.join("");
      if (this.form.address) {
        val = val.concat(this.form.address);
      }
      this.$refs.mapSearch.handleSearch(val);
    },
    handleSubmit(form) {
      console.log(this.rentSale, "rentSale");
      this.$refs.cameraForm.validate((valid) => {
        if (!valid) {
          return;
        }
        const isLt2M =
            this.skin_file.size / 1024 / 1024 < 2 ||
            JSON.stringify(this.skin_file) == "{}";
        if (!isLt2M) {
          this.$message.error("封面图片上传大小不能超过 2MB!");
          return;
        }
        if (form.phone == "") {
          this.$message({
            showClose: true,
            message: "请输入联系方式",
            type: "warning",
          });
          return;
        }
        if (this.rentSale.length == 2) {
          this.order_type = 3;
        } else {
          this.rentSale.forEach((res) => {
            if (res == "销售") {
              this.order_type = 2;
            } else if (res == "出租") {
              this.order_type = 1;
            }
          });
        }

        // console.log("this.skin_filethis.skin_filethis.skin_filethis.skin_filethis.skin_file",this.skin_file)
        this.handleBase64(this.skin_file).then((res) => {
          let data = {
            goodsName: form.goods_name,
            skin: res,
            images: form.goods_img.join("|"),
            bwh: form.bwh,
            size: form.size,
            linkType: form.contact_way,
            phone: form.phone,
            style: form.style,
            deposit: form.deposit,
            price: form.price,
            sellPrice: form.sellprice,
            city: form.district,
            address: form.address,
            synopsis: form.synopsis,
            detail: form.summary,
            showStatus: form.is_sale,
            position: this.position,
            order_type: this.order_type,
          };
          if (this.$route.query.id) {
            data.goodsID = this.$route.query.id;
            this.handleChangeLease(data).then((res) => {
              // this.$message({
              //     showClose: true,
              //     message: res.msg,
              //     type: res.type? "success": "error"
              // })
              this.$alert(res.msg, "提示", {
                confirmButtonText: "确定",
              });
            });
          } else {
            this.handleAddLease(data).then((res) => {
              // this.$message({
              //     showClose: true,
              //     message: res.msg,
              //     type: res.type? "success": "error"
              // })
              this.$alert(res.msg, "提示", {
                confirmButtonText: "确定",
              });
              if (res.type) this.$router.push("/center/camera");
            });
          }
        });
      });
    },
  },
  watch: {
    lease_detail: {
      handler: "setForm",
      immediate: true,
      deep: true,
    },
  },
  beforeRouteEnter(to, from, next) {
    next((vm) => {
      if (to.query.id) {
        vm.getLeaseDetail(to.query.id);
      }
    });
  },
  beforeRouteLeave(to, from, next) {
    this.setLeaseDetail({
      goods_address: "",
      goods_city: "",
      position: {},
    });
    this.form = {
      goods_name: "",
      goods_skin: "",
      goods_img: [],
      bwh: "",
      size: "",
      phone: "",
      style: [],
      deposit: "",
      price: "",
      sellprice: "",
      district: [],
      address: "",
      synopsis: "",
      summary: "",
      is_sale: 1,
    };
    next();
  },
};
</script>

<style scoped>
/* element-ui */
.form-input {
  width: 200px;
}
.form-radio-group {
  margin-right: 10px;
}
.form-input-address {
  width: 260px;
}

.camera-info-form {
  margin-top: 30px;
}
/* 相机信息表单标题 */
.camera-info-form-title {
  font-size: 20px;
  font-weight: bold;
  color: #303133;
}
/* 商品图片 */
.camera-info-pic-box,
.camera-info-pic-upload,
.camera-info-pic {
  display: inline-block;
  vertical-align: middle;
}
.camera-info-pic-upload,
.camera-info-pic {
  margin: 0 8px 8px 0;
}
.camera-info-pic {
  position: relative;
  width: 148px;
  height: 148px;
  overflow: hidden;
  border: 1px solid #c0ccda;
  border-radius: 6px;
}
.camera-info-pic::before {
  display: block;
  content: "";
  padding-top: 100%;
}
.camera-info-pic > img,
.camera-info-pic-delete-box,
.camera-info-pic-delete {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  max-width: 100%;
  max-height: 100%;
}
.camera-info-pic-delete-box {
  display: block;
  width: 100%;
  height: 100%;
  cursor: default;
  color: #fff;
  opacity: 0;
  font-size: 20px;
  background-color: rgba(0, 0, 0, 0.5);
  transition: opacity 0.3s;
}
.camera-info-pic-delete-box:hover {
  opacity: 1;
}
/* 所在城市组件 */
.center-basic-data-set-city >>> .area-select {
  width: 200px;
  height: 40px;
}
.center-basic-data-set-city >>> .area-selected-trigger {
  padding: 0 20px 0 12px;
}
/* 地图组件 */
.center-camera-info-map {
  margin-top: 10px;
}
</style>
